@font-face {
	font-family: 'FuturaPT';
    font-weight: 500;
	src: url('../fonts/FuturaPT/FuturaPT-Medium.woff2') format('woff2'),
		url('../fonts/FuturaPT/FuturaPT-Medium.woff') format('woff');
}

@font-face {
	font-family: 'FuturaPT';
    font-weight: 700;
	src: url('../fonts/FuturaPT/FuturaPT-Bold.woff2') format('woff2'),
		url('../fonts/FuturaPT/FuturaPT-Bold.woff') format('woff');
}