.socialMediaList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 17px 20px;
    position: absolute;
    right: 32px;
    width: 196px;
    top: 56px;
    background-color: #323540;
    border: 2px solid #444755;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.64);
    border-radius: 16px;

    @media (max-width: 700px) {
        right: 10px;
    }

    @media (max-width: 400px) {
        position: fixed;
        top: initial;
        width: initial;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 16px 16px 0 0;
        padding-bottom: 44px;
    }

    &__title {
        font-family: 'FuturaPT';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFF;
    }

    &__item {
        &:first-child {
            display: none;
            margin-bottom: 17px;

            @media (max-width: 375px) {
                display: flex;
            }
        }

        &:nth-child(2) {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &:not(:first-child, :nth-child(2)) {
            margin-top: 20px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: 12px;
    }

    &__name {
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
    }
}