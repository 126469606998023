@import url('./assets/styles/fonts.scss');

html,
body,
#root {
    height: 100%;
}

body {
    background-color: #0E0F12;
}