.tabsYourFurniActivityList {

    &::-webkit-scrollbar-thumb {
        background-color: #4F525C;
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 80px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 3px;
    }

    &__subTitle {
        max-width: 595px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #E0E0E0;
    }

    &__content {

        &::-webkit-scrollbar-thumb {
            background-color: #4F525C;
            border-radius: 8px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
        }
    }

    &__wrapper {
        position: relative;
        height: 504px;
        overflow-x: auto;
        
        &::-webkit-scrollbar-thumb {
            background-color: #4F525C;
            border-radius: 8px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
        }
    
        &::-webkit-scrollbar-track {
            background-color: #262830;
            border-radius: 3px;
        }
    }
}